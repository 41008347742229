.nav-transparent {
    padding: .75rem;
    --tw-text-opacity: 1;
    color: rgb(248 250 252 / var(--tw-text-opacity));
    display: flex;
    justify-content: space-between;
    height: 4rem;
    /* max-height: 5rem; */
    position: fixed;
    width: 100%;
    /* background-color: #930123; */
    transition: 0.2s ease;

    font-family: Verdana, Geneva, Tahoma, sans-serif;
    z-index: 99 !important;
}

.dropdown {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(248 250 252 / var(--tw-text-opacity));
    --tw-text-opacity: 1;
    transition: 0.2s ease;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    position: fixed;
    width: 100%;
    transition: 0.2s ease;
    /* opacity: 1; */
    border-top-width: 1.5px;
    border-color: black;
    z-index: 99 !important;


}



.hide {
    /* display: none; */ 
    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease;
}

.dropdown a {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.nav-bg {
    padding: .75rem;
    --tw-text-opacity: 1;
    color: rgb(248 250 252 / var(--tw-text-opacity));
    display: flex;
    justify-content: space-between;
    height: 4rem;
    /* max-height: 5rem; */
    position: fixed;
    top:0;
    width: 100%;
    background-color: #930123;
    transition: 0.2s ease;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    z-index: 99 !important;
    /* sshadow */
    filter: drop-shadow(0 15px 6px rgb(0 0 0 / 0.15));
}

.site-name-hide {
    margin-left: 1rem;
    opacity: 0;
    transition: 0.2s ease;
    pointer-events: none;
}

.site-name-scroll {
    margin-left: 1rem;
    display: block;
    pointer-events: all;
    transition: 0.2s ease;
    max-height: 1.5rem;
    overflow: hidden;

}

/* @media (min-width: 640px) {
    .site-name-scroll {
        margin-left: 1rem;
            display: block;
        transition: 0.2s ease;

    }
    
} */

.links a:hover {
    color: #d08a9b;
    transition: 0.2s ease;
}