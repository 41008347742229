.test iframe{
    width: 100%;
    height: 100%;
}

.test {
    aspect-ratio: 3/2;
    width: 100%;
}

.infinite-scroll-component__outerdiv {
    width: 100%;
    /* ...whatever more styles you want */
}